.back-to-top {
  display: block;
  position: fixed;
  right: 3%;
  bottom: 6%;
  transition: all .3s ease-in-out;
  opacity: .5;
  z-index: 99;
  &:hover {
    opacity: 1; }
  @media only screen and (max-width: 700px) {
    right: 2%;
    bottom: 1%; } }
