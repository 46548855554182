.garanty {
  &__wrapper {
    margin: 40px 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    &-text {
      max-width: 1000px;
      margin: 0 auto;
      & > * {
        &:not(:last-child) {
          margin-bottom: 30px; } } } }
  &__case {
    margin: 0 70px 60px;
    width: 244px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    &--img {
      height: 70px;
      img {
        height: 100%; } }
    &--text {
      margin-top: 10px; } } }


