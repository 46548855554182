.prices {
  &__wrapper {
    display: flex;
    flex-direction: row;
    margin-left: -20px;
    margin-top: 30px;
    @media only screen and (max-width: 1050px) {
      margin-left: 0; }
    @media only screen and (max-width: 900px) {
      overflow: auto;
      white-space: nowrap; }
    & > * {
      &:not(:last-child) {
        margin-right: 30px; } }
    &--column {
      display: flex;
      flex-direction: column;
      & > * {
        padding: 10px 20px; }
      &-red-item {
        background-color: $main__red;
        color: $main__white;
        min-height: 23px;
        border-radius: 3px; }
      &-item {
        background-color: $main__red;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
        border-radius: 3px;
        padding-left: 20px; }
      &-align-end {
        align-items: flex-end; }
      &-red {
        background-color: $main__red;
        border-radius: 3px;
        & > * {
          color: $main__white; } } } } }

