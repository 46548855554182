.services {
  padding: 80px 20px 60px;
  @media only screen and (max-width: 900px) {
    padding: 50px 0; }
  &__wrapper {
    margin: 50px -20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media only screen and (max-width: 1192px) {
      justify-content: center;
      margin: 30px -20px 0; } }


  &__case {
    display: block;
    width: 246px;
    height: 331px;
    margin: 0 20px;
    border-radius: 3px;
    border: 1px solid $main__red;

    position: relative;
    transition: all .3s ease-in-out;
    &:not(:last-child) {
      margin-bottom: 30px; }
    &:hover {
      box-shadow: 0px 0px 20px rgba(224, 44, 44, 0.7); }
    &--img {
      width: 100%;
      height: 173px;
      img {
        width: 100%;
        height: 100%; } }
    &--wrapper {
      padding: 23px 8px;
      text-align: center; }
    &--title {
      line-height: 1.2; }
    &--info {
      font-weight: 300;
      margin-top: 10px;
      line-height: 1.2; }
    &--text {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      bottom: 20px; } } }



