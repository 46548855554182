.advantages {
  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 880px;
    margin: 0 auto;
    margin-top: 53px;
    & > * {
      &:not(:last-child) {
        margin-bottom: 90px; } }
    @media only screen and (max-width: 900px) {
      justify-content: center; }
    @media only screen and (max-width: 590px) {
      flex-direction: column;
      align-items: center; } }
  &__case {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    max-width: 231px;
    margin: 0 30px;
    @media only screen and (max-width: 900px) {
      margin: 0 20px; }
    &--img {
      height: 56px;
      img {
        height: 100%; } }
    &--text {
      margin-top: 10px; } } }
