.questions {
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 800px) {
      justify-content: center; } }
  &__info {
    @media only screen and (max-width: 800px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center; } }
  &__text {
    max-width: 312px;
    margin: 15px 0 20px; }
  &__phone {
    margin-bottom: 39px; }
  &__img {
    max-width: 648px;
    margin-right: -100px;
    @media only screen and (max-width: 1200px) {
      margin: 0; }
    @media only screen and (max-width: 1000px) {
      max-width: 448px; }
    @media only screen and (max-width: 800px) {
      display: none; }
    img {
      width: 100%; } } }


.question {
  color: $main__red;
  font-size: 100px;
  font-family: $pt__sans; }
