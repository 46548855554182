.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);
  transition: all .3s ease-in-out;
  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 950px) {
      margin-top: 74px;
      overflow: scroll;
      height: auto; } }
  &__form {
    position: relative;
    padding: 30px;
    background-color: rgba(255, 255, 255, .8);
    width: fit-content;
    max-width: 500px;
    text-align: center;
    @media only screen and (max-width: 590px) {
      padding: 15px 20px; }
    h1 {
      @media only screen and (max-width: 590px) {
        font-size: 14px;
        line-height: 20px; } }
    &--close {
      position: absolute;
      top: 4px;
      right: 4px;
      width: 15px;
      cursor: pointer;
      img {
        width: 100%; } }
    &--field {
      margin: 20px 0;
      text-align-last: left;
      width: 100%;
      @media only screen and (max-width: 590px) {
        margin: 10px 0; }
      input {
        border: none;
        outline: none;
        // padding: 10px
        width: 100%;
        height: 50px;
        @media only screen and (max-width: 590px) {
          height: 30px; }

        font-family: $russo__one;
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        line-height: 19px;
        letter-spacing: 0.05em;
        color: $main__black;
        transition: all .3s ease-in-out; }
      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        appearance: none;
        margin: 0; }
      span {
        font-family: $pt__sans;
        font-weight: 100;
        color: $main__red; } } } }

.modal-success {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);
  transition: all .3s ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;
  &__wrapper {
    background-color: rgba(255, 255, 255, .9);
    width: fit-content;
    max-width: 500px;
    text-align: center;
    padding: 20px; } }


