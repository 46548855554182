.header-template {
  background: linear-gradient(181.18deg, #262626 54.6%, rgba(38, 38, 38, 0) 99.24%);
  background-position: center;
  background-size: cover;
  &__inner-wrapper {
    padding: 20px 20px 110px;
    background-color: $bg__dark;
    background-position: center;
    background-size: cover;
    min-height: 390px;
    @media only screen and (max-width: 950px) {
      padding: 120px 40px 100px;
      margin: 0 20px;
      min-height: auto; }
    @media only screen and (max-width: 530px) {
      padding: 100px 0 100px; }
    &-index {
      padding: 120px 20px 115px;
      background-image: url(../img/pages/main/bg.png); }
    &-prices {
      background-image: url(../img/pages/prices/bg.png); }
    &-gallery {
      background-image: url(../img/pages/gallery/bg.png); }
    &-akpp-repair {
      background-image: url(../img/pages/akpp-repair/bg.png); }
    &-variator {
      background-image: url(../img/pages/variator/bg.png); }
    &-dsg {
      background-image: url(../img/pages/dsg/bg.png); }
    &-rkpp {
      background-image: url(../img/pages/rkpp/bg.png); }
    &-oil {
      background-image: url(../img/pages/oil/bg.png); }
    &-hydroblock {
      background-image: url(../img/pages/hydroblock/bg.png); }
    &-complex-diagnostics {
      background-image: url(../img/pages/complex-diagnostics/bg.png); }
    &-torgue-converter {
      background-image: url(../img/pages/torgue-converter/bg.png); } }
  &__questions {
    &--wrapper {
      display: flex;
      flex-direction: row;
      margin-bottom: -60px;
      @media only screen and (max-width: 1450px) {
        margin-bottom: 0; } }
    &--question {
      width: 600px;
      margin-left: 50px;
      margin-right: -50px;
      @media only screen and (max-width: 1450px) {
        width: 200px;
        margin: 0; }
      @media only screen and (max-width: 600px) {
        display: none; }
      img {
        width: 100%; } } }
  &__dark {
    background-color: $bg__dark; }
  &__list {
    display: flex;
    flex-direction: column;
    margin: 33px 0 71px;
    &--item {
      display: flex;
      flex-direction: row;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 14px; } }
    &--img {
      margin-right: 19px; } }
  &__undertitle {
    margin: 10px 0 40px;
    &--title {
      margin-top: 30px; }
    &--wrapper {
      max-width: 880px;
      margin: 30px auto 0;
      @media only screen and (max-width: 480px) {
        margin: 30px 10px 0; }
      & > * {
        &:not(:last-child) {
          margin-bottom: 30px; } } } }
  &__main-text {
    margin-bottom: 72px;
    max-width: 477px;
    line-height: 28px; }
  &__adress {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 420px;
    margin: 20px auto 0;
    & > * {
      &:not(:last-child) {
        margin-right: 70px;
        @media only screen and (max-width: 500px) {
          margin-right: 30px; } } }
    &--wrapper {
      & > * {
        &:not(:last-child) {
          margin-bottom: 30px;
          @media only screen and (max-width: 500px) {
            margin-bottom: 10px; } } }
      &-mobile {
        @media only screen and (max-width: 500px) {
          display: none; } } }
    &-mobile {
      display: none;
      @media only screen and (max-width: 500px) {
        display: block; } } }

  &__buttons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 100px;
    @media only screen and (max-width: 950px) {
      margin-top: 30px; }
    @media only screen and (max-width: 770px) {
      flex-direction: column;
      align-items: flex-start;
      & > * {
        &:not(:last-child) {
          margin-bottom: 30px; } } }
    @media only screen and (max-width: 530px) {
      margin-top: 60px;
      align-items: center; } }
  &__title {
    &-rkpp {
      max-width: 620px; } } }
