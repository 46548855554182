.info-blocks {
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 50px -20px 0;
    @media only screen and (max-width: 1000px) {
      justify-content: center; }
    @media only screen and (max-width: 800px) {
      margin: 30px -20px 0; } }
  &__title {
    @media only screen and (max-width: 1000px) {
      text-align: center; } }
  &__block {
    width: 192px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 20px;
    &:not(:last-child) {
      margin-bottom: 50px; }
    &--title {
      margin-bottom: 15px;

      font-family: $montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 50px;
      line-height: 25px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: $main__red; }
    &--img {
      height: 56px;
      margin-bottom: 6px;
      img {
        height: 100%; } }

    &--text {
      line-height: 20px;
      font-size: 14px;
      width: 220px; } } }
