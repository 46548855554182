// Back to top
.back-to-top-is-hidden {
  opacity: 0;
  visibility: hidden; }
// FAQ
.icon-is-hidden {
  opacity: 0;
  visibility: hidden; }
.body-is-hidden {
  max-height: 0;
  opacity: 0;
  visibility: hidden; }
// Navigation
.menu-is-hidden {
  @media only screen and (max-width: 950px) {
    // opacity: 0
    // visibility: hidden
    transform: translateX(-100%); } }
// Body fixed
.body-is-fixed {
  overflow: hidden; }

// Modal
.modal-is-hidden {
  opacity: 0;
  visibility: hidden; }
