.checkers {
  padding: 70px 0;
  &__0 {
    padding: 0; }
  &__title {
    margin-bottom: 30px; }
  &__wrapper {
    margin-top: 38px;
    & > * {
      &:not(:last-child) {
        margin-bottom: 40px; } } }
  &__case {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media only screen and (max-width: 700px) {
      flex-direction: column; }
    &--reverse {
      flex-direction: row-reverse;
      @media only screen and (max-width: 700px) {
        flex-direction: column; } }
    &--img {
      width: 480px;
      border-radius: 3px;
      overflow: hidden;
      @media only screen and (max-width: 700px) {
        margin-bottom: 10px;
        width: 100%; }
      img {
        width: 100%; } }
    &--info {
      max-width: 483px;
      @media only screen and (max-width: 700px) {
        max-width: 100%; }
      &-straight {
        padding-left: 20px;
        @media only screen and (max-width: 700px) {
          padding: 0; } }
      &-reverse {
        padding-right: 20px;
        @media only screen and (max-width: 700px) {
          padding: 0; } }
      &-text {
        margin-top: 20px; } } } }

