.variator-dignities {
  &__blocks {
    margin: 60px auto 40px;
    max-width: 940px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    @media only screen and (max-width: 900px) {
      justify-content: center;
      // align-items: center
      & > * {
       &:not(:last-child) {
        margin: 0 30px 30px; } } } }
  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    max-width: 140px;
    &--img {
      max-height: 55px;
      img {
        height: 100%; } }
    &--title {
      margin-top: 15px; } }
  &__text-wrapper {
    max-width: 1050px;
    & > * {
      &:not(:last-child) {
        margin-bottom: 30px; } } } }
