.section {
  &__light {
    background-color: $bg__light; }
  &__dark {
    background-color: $bg__dark; }

  &__130 {
    padding: 130px 0;
    @media only screen and (max-width: 950px) {
      padding: 100px 0; }
    @media only screen and (max-width: 600px) {
      padding: 80px 0; }
    &--half-top {
      padding-top: 65px;
      @media only screen and (max-width: 950px) {
        padding-top: 50px; }
      @media only screen and (max-width: 600px) {
        padding-top: 40px; } }
    &--half-bottom {
      padding-bottom: 65px;
      @media only screen and (max-width: 950px) {
        padding-bottom: 50px; }
      @media only screen and (max-width: 600px) {
        padding-bottom: 40px; } }
    &--only-top {
      padding: 130px 0 0;
      @media only screen and (max-width: 950px) {
        padding: 100px 0 0; }
      @media only screen and (max-width: 600px) {
        padding: 80px 0 0; } }
    &--only-bottom {
      padding: 0 0 130px;
      @media only screen and (max-width: 950px) {
        padding: 0 0 100px; }
      @media only screen and (max-width: 600px) {
        padding: 0 0 80px; } } }
  &__70 {
    padding: 70px 0;
    @media only screen and (max-width: 950px) {
      padding: 60px 0; }
    @media only screen and (max-width: 600px) {
      padding: 50px 0; } } }


.title {
  font-family: $russo__one;
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 72px;
  letter-spacing: 0.26em;
  text-transform: uppercase;
  color: $main__black;
  @media only screen and (max-width: 950px) {
    font-size: 55px; }
  @media only screen and (max-width: 750px) {
    font-size: 32px;
    line-height: 40px; }
  &__light {
    color: $main__white; }
  &__red {
    color: $main__red; }
  &__bold {
    font-weight: bold; }

  &__20 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.05em; }
  &__16 {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.1em;
    @media only screen and (max-width: 950px) {
      font-size: 14px;
      line-height: 18px; }
    &--lh-25 {
      line-height: 25px; } }
  &__15 {
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.05em; }

  &__center {
    margin: 0 auto;
    text-align: center; }
  &__mobile-small {
    @media only screen and (max-width: 400px) {
      font-size: 25px;
      line-height: 30px; } }
  &__torgue-converter {
    @media only screen and (max-width: 1130px) {
      font-size: 32px;
      line-height: 39px; }
    @media only screen and (max-width: 670px) {
      font-size: 16px;
      line-height: 20px; } } }

.text {
  font-family: $montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $main__black;
  display: block;
  &__16 {
    font-size: 16px;
    line-height: 25px; }
  &__15 {
    font-size: 15px;
    line-height: 25px;
    @media only screen and (max-width: 368px) {
      font-size: 14px;
      line-height: 22px; } }
  &__bold {
    font-weight: bold; }
  &__uppercase {
    text-transform: uppercase; }
  &__center {
    text-align: center; }

  &__light {
    color: $main__white; }
  &__red {
    line-height: 16px;
    text-align: center;
    color: $main__red; } }

.phone {
  font-family: $pt__sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: $main__black;

  display: flex;
  flex-direction: row;
  align-items: center;
  &--phone {
    margin-left: 13px; }
  &__white {
    color: $main__white; } }

.margin {
  margin: 30px 0;
  &__top {
    margin: 30px 0 0; } }
