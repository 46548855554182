.how-do-we-work {
  padding: 70px 0 20px;
  @media only screen and (max-width: 1200px) {
    padding: 70px 0 10px; }
  &__wrapper {
    margin-top: 66px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media only screen and (max-width: 1200px) {
      justify-content: center;
      margin: 66px -20px 0; }
    @media only screen and (max-width: 800px) {
      margin-top: 40px; } }
  &__case {
    margin-bottom: 50px;
    @media only screen and (max-width: 1200px) {
      margin: 0 20px 50px; }
    &--text {
      height: 44px;
      margin-bottom: 20px;
      max-width: 187px;
      line-height: 22px; }
    &--img {
      max-width: 278px;
      max-height: 185px;
      border-radius: 3px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%; } } } }
