.how-to-repair-akpp {
  &--text {
    max-width: 1020px;
    margin-top: 30px; }
  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 32px;
    @media only screen and (max-width: 1200px) {
      justify-content: center; } }
  &__case {
    max-width: 341px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px 50px;
    @media only screen and (max-width: 600px) {
      margin: 0 0 20px; }
    &--img {
      max-width: 341px;
      img {
        width: 100%; } }
    &--text {
      max-width: 220px;
      line-height: 20px;
      margin-top: 14px; } } }
