.after-head-banner {
  padding-bottom: 65px;
  &__body {
    max-width: 625px;
    margin: -50px auto 0;
    padding: 50px 60px 57px;
    background-color: $tertiary__grey;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    @media only screen and (max-width: 950px) {
      padding: 20px 25px; }
    & > * {
      &:not(:last-child) {
        margin-bottom: 30px; } } } }
