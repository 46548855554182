.repair {
  &__items-wrapper {
    margin: 60px 0 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    @media only screen and (max-width: 1020px) {
      margin: 30px 0; } }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    width: 286px;
    margin: 0 20px 30px;
    &--img {
      height: 50px;
      img {
        height: 100%; } }
    &--title {
      margin-top: 10px; } } }
