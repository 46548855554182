.navigation {
  transition: 0.4s cubic-bezier(1, 0.01, 0.2, 1);
  @media only screen and (max-width: 950px) {
    position: fixed;
    top: 55px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: $bg__dark;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: scroll; }
  &__wrapper {
    max-width: 780px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 950px) {
      @media only screen and (max-height: 800px) {
        padding: 150px 0 100px; }
      @media only screen and (max-height: 620px) {
        padding: 250px 0 100px; }
      @media only screen and (max-height: 520px) {
        padding: 300px 0 100px; }
      @media only screen and (max-height: 470px) {
        padding: 400px 0 100px; } }
    ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: 950px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        overflow: hidden; }
      li {
        position: relative;
        &:not(:last-child) {
          margin-right: 30px;
          @media only screen and (max-width: 950px) {
            margin-right: 0; } }
        &:not(:first-child) {
          &:before {
            content: url('../img/icons/dott.svg');
            position: absolute;
            top: 22px;
            left: -18px;
            width: 1px;
            height: 1px;
            @media only screen and (max-width: 950px) {
              display: none; } } }
        &:hover {
          ul.sub-menu {
            opacity: 1;
            visibility: visible;
            transform: translateY(0); } }
        a {
          display: block;
          padding: 20px 0;
          font-family: $montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 23px;
          color: $main__grey;
          transition: all .3s ease-in-out;
          -webkit-tap-highlight-color: rgba(0,0,0,0);
          @media only screen and (max-width: 950px) {
            color: $main__white;
            padding: 10px 0; }
          &:hover {
            color: $main__white;
            text-shadow: 0 0 .75px $main__white, 0 0 .75px $main__white; }
          &--services {
            position: relative;
            cursor: pointer;
            @media only screen and (max-width: 950px) {
              -webkit-tap-highlight-color: rgba(0,0,0,0); }
            &:hover {
              .navigation__link--hovered {
                transform: translateY(0);
                opacity: 1;
                visibility: visible; } } } }
        ul.sub-menu {
          padding: 7px 20px;
          background-color: $main__white;
          box-shadow: 0px 0px 15px rgba(224, 44, 44, 0.7);
          border-radius: 0px 3px 3px 3px;
          position: absolute;
          top: 66px;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          white-space: nowrap;
          transform: translateY(-20px);
          opacity: 0;
          visibility: hidden;
          transition: all .3s ease-in-out;
          -webkit-tap-highlight-color: rgba(0,0,0,0);
          @media only screen and (max-width: 950px) {
            position: relative;
            opacity: 1;
            visibility: visible;
            top: 0;
            background-color: $bg__dark;
            box-shadow: none;
            border-radius: none;
            padding: 5px 10px 0;
            transform: translateY(0); }
          &:before {
            content: '';
            clip-path: polygon(50% 0%, 0 70%, 100% 70%);
            position: absolute;
            width: 28px;
            height: 20px;
            top: -13px;
            left: 15px;
            background-color: $main__white;
            box-shadow: 0px 0px 15px rgba(224, 44, 44, 0.7);
            @media only screen and (max-width: 950px) {
              display: none; } }
          & li {
            margin: 0;
            padding: 0;
            &:before {
              display: none; } }
          & li a {
            font-family: $montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 1px;
            color: $main__grey;
            display: block;
            transition: all .3s ease-in-out;
            @media only screen and (max-width: 950px) {
              color: $main__white;
              opacity: .7;
              line-height: 20px; }
            &:hover {
              color: $main__black;
              text-shadow: 0 0 .75px $main__black, 0 0 .75px $main__black; } } } } } } }
