@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Black.woff2') format("woff2");
  font-weight: 900;
  font-style: normal; }
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-ExtraBold.woff2') format("woff2");
  font-weight: 800;
  font-style: normal; }
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold.woff2') format("woff2");
  font-weight: 700;
  font-style: normal; }
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-SemiBold.woff2') format("woff2");
  font-weight: 600;
  font-style: normal; }
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Medium.woff2') format("woff2");
  font-weight: 500;
  font-style: normal; }
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.woff2') format("woff2");
  font-weight: 400;
  font-style: normal; }
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Light.woff2') format("woff2");
  font-weight: 300;
  font-style: normal; }
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-ExtraLight.woff2') format("woff2");
  font-weight: 200;
  font-style: normal; }
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Thin.woff2') format("woff2");
  font-weight: 100;
  font-style: normal; }



@font-face {
  font-family: 'Russo One';
  src: url('../fonts/RussoOne-Regular.woff2') format("woff2");
  font-weight: 400;
  font-style: normal; }


@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/PT_Sans-Web-Regular.woff2') format("woff2");
  font-weight: 400;
  font-style: normal; }
@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/PT_Sans-Web-Bold.woff2') format("woff2");
  font-weight: 700;
  font-style: normal; }


