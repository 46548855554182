.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 28px;
  @media only screen and (max-width: 950px) {
    display: none; }
  &__link,
  &__current {
    font-family: $pt__sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: $main__white;
    opacity: .3;
    transition: all .3s ease-in-out;
    &:hover {
      opacity: .7; } }
  &__current {
    opacity: .6; }
  &__separator {
    margin: 0 10px;
    width: 12px;
    opacity: .3;
    position: relative;
    color: $main__white;
    font-size: 10px; } }


