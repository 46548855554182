.brands {
  &__wrapper {
    margin: 33px -20px 0;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 1100px) {
      margin: 33px 0 0; } }
  &__brand {
    background: $main__white;
    border: 1px solid #F3F1F1;
    box-sizing: border-box;
    border-radius: 3px;

    width: 122px!important;
    height: 128px!important;
    margin: 0 20px 35px;
    padding: 14px 9px 40px;
    position: relative;

    display: flex!important;
    flex-direction: column!important;
    align-items: center!important;
    justify-content: center!important;
    outline: none;

    transition: all .3s ease-in-out;
    @media only screen and (max-width: 1100px) {
      margin: 7px 15px 20px 8px; }
    &:hover {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      @media only screen and (max-width: 1100px) {
        box-shadow: none; } }
    &--text {
      position: absolute;
      bottom: 12px; }

    &--img {
      img {
        width: 100%; } }
    &--acura {
      width: 69px; }
    &--audi {
      width: 106px; }
    &--bmw {
      width: 67px; }
    &--chevrolet {
      width: 103px; }
    &--citroen {
      width: 95px; }
    &--cadillac {
      width: 61px; }
    &--daewoo {
      width: 64px; }
    &--fiat {
      width: 71px; }
    &--jeep {
      width: 102px; }
    &--ford {
      width: 103px; }
    &--infiniti {
      width: 106px; }
    &--kia {
      width: 103px; }
    &--dodge {
      width: 64px; }
    &--hyundai {
      width: 102px; }
    &--land-rover {
      width: 94px; }
    &--honda {
      width: 101px; }
    &--mitsubishi {
      width: 60px; }
    &--mazda {
      width: 104px; }
    &--mb {
      width: 68px; }
    &--porche {
      width: 105px; }
    &--opel {
      width: 86px; }
    &--isuzu {
      width: 102px; }
    &--renault {
      width: 58px; }
    &--lexus {
      width: 101px; }
    &--nissan {
      width: 79px; }
    &--sy {
      width: 102px; }
    &--saab {
      width: 67px; }
    &--suzuki {
      width: 104px; }
    &--subaru {
      width: 105px; }
    &--peugueot {
      width: 97px; }
    &--wolkswagen {
      width: 64px; }
    &--skoda {
      width: 57px; }
    &--toyota {
      width: 81px; }
    &--jaguar {
      width: 102px; }
    &--ar {
      width: 66px; }
    &--hummer {
      width: 104px; }
    &--volvo {
      width: 67px; } } }

.slick-slide {
  outline: none; }
