.equipment {
  padding: 80px 0 50px;
  @media only screen and (max-width: 900px) {
    padding: 60px 0 30px; }
  &__wrapper {
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 48px -10px 0;
    @media only screen and (max-width: 1282px) {
      margin: 48px 0 0; }
    @media only screen and (max-width: 900px) {
      margin-top: 40px; } }
  &__case {
    width: 280px;
    height: 670px;
    margin: 0 10px 30px;
    border: 1px solid $main__red;
    box-sizing: border-box;
    border-radius: 3px;
    overflow: hidden;
    transition: all .3s ease-in-out;
    &:hover {
      box-shadow: 0px 0px 20px rgba(224, 44, 44, 0.7); }
    &--img {
      width: 100%;
      height: 321px;
      img {
        width: 100%;
        height: 100%; } }
    &--wrapper {
      padding: 20px 20px; }
    &--info {
      opacity: .5;
      margin-top: 20px; } } }
