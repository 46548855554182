.header {
  background-color: $bg__dark; }

.header-top {
  padding: 17.5px 0 13.5px;
  border-bottom: 1px solid rgba(255, 255, 255, .4);
  @media only screen and (max-width: 950px) {
    border: none;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $bg__dark;
    width: 100%;
    z-index: 9999; }
  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (max-width: 950px) {
      justify-content: center; }
    &--wrap {
      display: flex;
      flex-direction: row;
      @media only screen and (max-width: 950px) {
        flex-direction: row-reverse; }
      &-right {
        margin-bottom: 5px; } } }
  &__logo {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-right: 65px;
    @media only screen and (max-width: 950px) {
      display: none; }
    &--mobile {
      display: none;
      @media only screen and (max-width: 950px) {
        display: flex;
        margin: 0 auto; } }
    &--text {
      font-family: $montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: $main__red;
      max-width: 104px;
      margin-left: 13px;
      @media only screen and (max-width: 650px) {
        margin-left: 8px; } }
    &--img {
      @media only screen and (max-width: 650px) {
        width: 40px; }
      img {
        width: 100%; } } }
  &__hours {
    font-family: $pt__sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: $main__white;
    margin-top: 3px;
    @media only screen and (max-width: 950px) {
      width: 110px;
      margin-left: 30px;
      margin-top: 0; }
    @media only screen and (max-width: 650px) {
      display: none; }
    span {
      font-weight: 100; } }
  &__button {
    font-family: $russo__one;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $main__red;

    margin-right: 21px;
    width: 221px;
    height: 37px;
    background-image: url(../img/button-bg.png);
    background-size: cover;
    background-position: center;

    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 950px) {
      display: none; } }
  &__phone {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    &--phone {
      font-family: $pt__sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      color: $main__white;
      margin-left: 13px;
      @media only screen and (max-width: 650px) {
        display: none; } } } }







