.slider {
  padding: 70px 0 156px;
  @media only screen and (max-width: 600px) {
    padding: 50px 0 150px; }
  @media only screen and (max-width: 450px) {
    padding: 50px 0 100px; }
  &__gallery {
    padding: 130px 0 0;
    @media only screen and (max-width: 1010px) {
      padding: 50px 0 100px; }
    @media only screen and (max-width: 700px) {
      padding: 50px 0 50px; } }
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 50px auto 0;
    max-width: 780px;
    @media only screen and (max-width: 650px) {
      margin-top: 30px; } }
  &__slide {
      outline: none;
      display: block;
      overflow: hidden;
      &--img {
        max-width: 780px;
        outline: none;
        img {
          width: 100%;
          height: auto; } } }
  &__iframe {
    outline: none;
    height: 439px!important;
    overflow: hidden!important;
    @media only screen and (max-width: 650px) {
      height: 350px!important; }
    @media only screen and (max-width: 550px) {
      height: 290px!important; }
    @media only screen and (max-width: 450px) {
      height: 200px!important; }

    iframe, object, embed {
      display: block!important;
      width: 100%!important;
      height: 100%!important; } }
  &__arrow {
    width: 71px;
    height: 71px;
    cursor: pointer;
    display: block;
    background: none;
    border: none;
    z-index: 99;
    img {
      width: 100%; }
    @media only screen and (max-width: 600px) {
      width: 51px;
      height: 51px; }
    &--left {
      margin-left: -73px;
      @media only screen and (max-width: 1010px) {
        position: absolute;
        top: 117%;
        left: 40%; }
      @media only screen and (max-width: 600px) {
        top: 120%; }
      @media only screen and (max-width: 400px) {
        top: 127%; } }
    &--right {
      margin-right: -73px;
      @media only screen and (max-width: 1010px) {
        position: absolute;
        top: 117%;
        right: 40%; }
      @media only screen and (max-width: 600px) {
        top: 120%; }
      @media only screen and (max-width: 400px) {
        top: 127%; } }
    img {
      width: 100%; } } }

.gallery__arrow {
  width: 71px;
  height: 71px;
  cursor: pointer;
  display: block;
  background: none;
  border: none;
  z-index: 99;
  outline: none;
  img {
    width: 100%; }
  @media only screen and (max-width: 600px) {
    width: 51px;
    height: 51px; }
  &--left {
    position: absolute;
    left: -5%;
    margin-left: -73px !important;
    @media only screen and (max-width: 1010px) {
      bottom: -25%;
      left: 40%; }
    @media only screen and (max-width: 400px) {
      bottom: -30%; } }
  &--right {
    margin-right: -73px;
    position: absolute;
    right: -3%;
    @media only screen and (max-width: 1010px) {
      bottom: -25%;
      right: 40%; }
    @media only screen and (max-width: 400px) {
      bottom: -30%; } } }

.slick-prev:before,
.slick-next:before {
  display: none; }





