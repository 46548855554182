.footer {
  display: flex;
  flex-direction: row;
  max-height: 560px;
  @media only screen and (max-width: 950px) {
    flex-direction: column; }
  &__map {
    width: 100%;
    z-index: 1;
    @media only screen and (max-width: 950px) {
      height: 400px; }
    iframe {
      border: 0;
      padding: 0;
      margin: 0;
      display: block; } }
  &__info {
    padding: 72px 140px 50px 72px;
    width: 480px;
    @media only screen and (max-width: 950px) {
      width: auto;
      padding: 50px 80px;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
    @media only screen and (max-width: 700px) {
      padding: 40px 20px; }
    @media only screen and (max-width: 560px) {
      flex-direction: column;
      padding: 40px 20px 0; }
    &--wrapper {
      &-mobile {
        display: none;
        @media only screen and (max-width: 950px) {
          display: block; } } } }
  &__logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    &--text {
      font-family: $montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: $main__red;
      max-width: 104px;
      margin-left: 13px; } }
  &__hours {
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: $main__white;
    margin: 42px 0 28px;
    span {
      font-weight: bold; } }
  &__mail,
  &__adress,
  &__inst,
  &__rights {
    font-family: $pt__sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: $secondary__grey;
    display: block;
    transition: all .3s ease-in-out;
    &:hover {
      color: $main__white; } }
  &__adress {
    margin: 38px 0 31px;
    max-width: 278px;
    span {
      font-weight: bold; } }
  &__inst {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 37px;
    @media only screen and (max-width: 950px) {
      margin-top: 11px; }
    &--img {
      margin-left: 13px; } }
  &__rights {
    color: $main__white;
    font-size: 14px;
    line-height: 15px;
    max-width: 290px; } }


.fmdn {
  @media only screen and (max-width: 950px) {
    display: none; } }

