
@import "utils/reset";
@import "utils/variables";
@import "utils/mixins";
@import "utils/fonts";
@import "utils/libs";
@import "utils/general-classes";
@import "utils/back-to-top";
@import "utils/script-classes";

@import "modules/modules";

@import "pages/pages";


body {
  font-family: $russo__one;
  font-weight: normal;
  color: $main__black;
  position: relative; }

.container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  &__middle {
    max-width: 980px; }
  &__thin {
    max-width: 860px; }
  &__text-center {
    text-align: center;
    @media only screen and (max-width: 800px) {
      text-align: left; } }
  &__mobile-header {
    padding: 0; } }


