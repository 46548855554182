.checkpoints {
  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    @media only screen and (max-width: 650px) {
      flex-direction: column;
      margin-top: 20px; }
    &--inner {
      &:not(:last-child) {
        @media only screen and (max-width: 650px) {
          margin-bottom: 20px; } } } }
  &__checkpoint {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media only screen and (max-width: 650px) {
      text-align: left; }
    &:not(:last-child) {
      margin-bottom: 20px; }
    &--img {
      margin-right: 23px; } } }
