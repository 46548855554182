.masters {
  text-align: center;
  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1140px;
    margin: 60px auto 0;
    @media only screen and (max-width: 1180px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & > * {
        &:not(:last-child) {
          margin-bottom: 40px;
          @media only screen and (max-width: 590px) {
            margin-bottom: 30px; } } } } }
  &__master {
    background: $main__white;
    box-shadow: 0px 0px 15px rgba(224, 44, 44, 0.25);
    border-radius: 0px 3px 3px 0px;
    text-align: left;
    width: 543px;
    height: 340px;
    display: flex;
    flex-direction: row;
    transition: all .3s ease-in-out;
    @media only screen and (max-width: 590px) {
      flex-direction: column;
      width: auto;
      height: auto; }
    &:hover {
      box-shadow: 0px 0px 25px rgba(224, 44, 44, 0.25); }
    &--img {
      width: 275px;
      height: 100%;
      img {
        width: 100%;
        height: 100%; } }
    &--info {
      padding: 75px 31px;
      @media only screen and (max-width: 590px) {
        padding: 50px 30px; } }
    &--title {
      font-weight: bold; }
    &--text {
      margin: 30px 0 60px; }
    &--muted {
      opacity: .4;
      max-width: 214px; } } }
