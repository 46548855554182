.banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &__line {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: 1px;
    width: 100%;
    border-bottom: 1px dashed $main__red;
    z-index: 0; }
  &__body {
    padding: 30px 94px;
    border: 1px dashed $main__red;
    border-radius: 3px;
    width: fit-content;
    margin: 0 20px;
    background-color: $main__white;
    z-index: 2;
    position: relative;
    @media only screen and (max-width: 800px) {
      padding: 30px 35px; }
    &-margin {
      margin-top: 25px; }
    &--text-center {
      text-align: center; }
    &--dark {
      background-color: $bg__dark; } } }
