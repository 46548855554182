.button {
  padding: 16px 30px;
  background-color: $main__red;
  border-radius: 3px;
  border: 1px solid $main__red;
  display: inline-block;

  font-family: $russo__one;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: $main__white;
  transition: all .3s ease-in-out;
  outline: none;
  @media only screen and (max-width: 950px) {
    font-size: 14px;
    padding: 15px 28px; }
  &:hover {
    box-shadow: 0px 0px 20px rgba(224, 44, 44, 0.7); }
  &__transparent {
    background-color: transparent; }
  &__header {
    margin-right: 30px;
    @media only screen and (max-width: 770px) {
      margin: 0; } } }
