.it-block {
  &__wrapper {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 850px) {
      flex-direction: column; }
    &--reverse {
      flex-direction: row-reverse;
      @media only screen and (max-width: 850px) {
        flex-direction: column; } } }
  &__img {
    max-width: 378px;
    border-radius: 3px;
    overflow: hidden;
    @media only screen and (max-width: 850px) {
      margin-bottom: 20px; }
    img {
      width: 100%;
      height: auto; } }
  &__text {
    max-width: 647px;
    &--straight {
      margin-left: 20px;
      @media only screen and (max-width: 850px) {
        margin: 0; } }
    &--reverse {
      margin: 0 70px 0 0; }
    & > * {
      &:not(:last-child) {
        margin-bottom: 30px; } } } }
