.info {
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 860px) {
      flex-direction: column;
      align-items: flex-start; }
    &--block {
      &-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 100px;
        line-height: 25px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: $main__red;
        @media only screen and (max-width: 860px) {
          font-size: 80px; }
        span {
          font-size: 50px;
          margin-left: 30px;
          margin-top: 40px;
          @media only screen and (max-width: 860px) {
            font-size: 30px; } } } } }

  &__text {
    max-width: 522px;
    margin-top: 20px;
    @media only screen and (max-width: 860px) {
      margin-bottom: 30px; } } }

