@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

a {
  text-decoration: none; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-ExtraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Russo One';
  src: url("../fonts/RussoOne-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'PT Sans';
  src: url("../fonts/PT_Sans-Web-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'PT Sans';
  src: url("../fonts/PT_Sans-Web-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.section__light {
  background-color: #FFFFFF; }

.section__dark {
  background-color: #262626; }

.section__130 {
  padding: 130px 0; }
  @media only screen and (max-width: 950px) {
    .section__130 {
      padding: 100px 0; } }
  @media only screen and (max-width: 600px) {
    .section__130 {
      padding: 80px 0; } }
  .section__130--half-top {
    padding-top: 65px; }
    @media only screen and (max-width: 950px) {
      .section__130--half-top {
        padding-top: 50px; } }
    @media only screen and (max-width: 600px) {
      .section__130--half-top {
        padding-top: 40px; } }
  .section__130--half-bottom {
    padding-bottom: 65px; }
    @media only screen and (max-width: 950px) {
      .section__130--half-bottom {
        padding-bottom: 50px; } }
    @media only screen and (max-width: 600px) {
      .section__130--half-bottom {
        padding-bottom: 40px; } }
  .section__130--only-top {
    padding: 130px 0 0; }
    @media only screen and (max-width: 950px) {
      .section__130--only-top {
        padding: 100px 0 0; } }
    @media only screen and (max-width: 600px) {
      .section__130--only-top {
        padding: 80px 0 0; } }
  .section__130--only-bottom {
    padding: 0 0 130px; }
    @media only screen and (max-width: 950px) {
      .section__130--only-bottom {
        padding: 0 0 100px; } }
    @media only screen and (max-width: 600px) {
      .section__130--only-bottom {
        padding: 0 0 80px; } }

.section__70 {
  padding: 70px 0; }
  @media only screen and (max-width: 950px) {
    .section__70 {
      padding: 60px 0; } }
  @media only screen and (max-width: 600px) {
    .section__70 {
      padding: 50px 0; } }

.title {
  font-family: "Russo One", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 72px;
  letter-spacing: 0.26em;
  text-transform: uppercase;
  color: #000000; }
  @media only screen and (max-width: 950px) {
    .title {
      font-size: 55px; } }
  @media only screen and (max-width: 750px) {
    .title {
      font-size: 32px;
      line-height: 40px; } }
  .title__light {
    color: #ffffff; }
  .title__red {
    color: #ED2D2D; }
  .title__bold {
    font-weight: bold; }
  .title__20 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.05em; }
  .title__16 {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.1em; }
    @media only screen and (max-width: 950px) {
      .title__16 {
        font-size: 14px;
        line-height: 18px; } }
    .title__16--lh-25 {
      line-height: 25px; }
  .title__15 {
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.05em; }
  .title__center {
    margin: 0 auto;
    text-align: center; }
  @media only screen and (max-width: 400px) {
    .title__mobile-small {
      font-size: 25px;
      line-height: 30px; } }
  @media only screen and (max-width: 1130px) {
    .title__torgue-converter {
      font-size: 32px;
      line-height: 39px; } }
  @media only screen and (max-width: 670px) {
    .title__torgue-converter {
      font-size: 16px;
      line-height: 20px; } }

.text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  display: block; }
  .text__16 {
    font-size: 16px;
    line-height: 25px; }
  .text__15 {
    font-size: 15px;
    line-height: 25px; }
    @media only screen and (max-width: 368px) {
      .text__15 {
        font-size: 14px;
        line-height: 22px; } }
  .text__bold {
    font-weight: bold; }
  .text__uppercase {
    text-transform: uppercase; }
  .text__center {
    text-align: center; }
  .text__light {
    color: #ffffff; }
  .text__red {
    line-height: 16px;
    text-align: center;
    color: #ED2D2D; }

.phone {
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .phone--phone {
    margin-left: 13px; }
  .phone__white {
    color: #ffffff; }

.margin {
  margin: 30px 0; }
  .margin__top {
    margin: 30px 0 0; }

.back-to-top {
  display: block;
  position: fixed;
  right: 3%;
  bottom: 6%;
  transition: all .3s ease-in-out;
  opacity: .5;
  z-index: 99; }
  .back-to-top:hover {
    opacity: 1; }
  @media only screen and (max-width: 700px) {
    .back-to-top {
      right: 2%;
      bottom: 1%; } }

.back-to-top-is-hidden {
  opacity: 0;
  visibility: hidden; }

.icon-is-hidden {
  opacity: 0;
  visibility: hidden; }

.body-is-hidden {
  max-height: 0;
  opacity: 0;
  visibility: hidden; }

@media only screen and (max-width: 950px) {
  .menu-is-hidden {
    transform: translateX(-100%); } }

.body-is-fixed {
  overflow: hidden; }

.modal-is-hidden {
  opacity: 0;
  visibility: hidden; }

.header {
  background-color: #262626; }

.header-top {
  padding: 17.5px 0 13.5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4); }
  @media only screen and (max-width: 950px) {
    .header-top {
      border: none;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #262626;
      width: 100%;
      z-index: 9999; } }
  .header-top__wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between; }
    @media only screen and (max-width: 950px) {
      .header-top__wrapper {
        justify-content: center; } }
    .header-top__wrapper--wrap {
      display: flex;
      flex-direction: row; }
      @media only screen and (max-width: 950px) {
        .header-top__wrapper--wrap {
          flex-direction: row-reverse; } }
      .header-top__wrapper--wrap-right {
        margin-bottom: 5px; }
  .header-top__logo {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-right: 65px; }
    @media only screen and (max-width: 950px) {
      .header-top__logo {
        display: none; } }
    .header-top__logo--mobile {
      display: none; }
      @media only screen and (max-width: 950px) {
        .header-top__logo--mobile {
          display: flex;
          margin: 0 auto; } }
    .header-top__logo--text {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #ED2D2D;
      max-width: 104px;
      margin-left: 13px; }
      @media only screen and (max-width: 650px) {
        .header-top__logo--text {
          margin-left: 8px; } }
    @media only screen and (max-width: 650px) {
      .header-top__logo--img {
        width: 40px; } }
    .header-top__logo--img img {
      width: 100%; }
  .header-top__hours {
    font-family: "PT Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin-top: 3px; }
    @media only screen and (max-width: 950px) {
      .header-top__hours {
        width: 110px;
        margin-left: 30px;
        margin-top: 0; } }
    @media only screen and (max-width: 650px) {
      .header-top__hours {
        display: none; } }
    .header-top__hours span {
      font-weight: 100; }
  .header-top__button {
    font-family: "Russo One", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ED2D2D;
    margin-right: 21px;
    width: 221px;
    height: 37px;
    background-image: url(../img/button-bg.png);
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media only screen and (max-width: 950px) {
      .header-top__button {
        display: none; } }
  .header-top__phone {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px; }
    .header-top__phone--phone {
      font-family: "PT Sans", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      color: #ffffff;
      margin-left: 13px; }
      @media only screen and (max-width: 650px) {
        .header-top__phone--phone {
          display: none; } }

.navigation {
  transition: 0.4s cubic-bezier(1, 0.01, 0.2, 1); }
  @media only screen and (max-width: 950px) {
    .navigation {
      position: fixed;
      top: 55px;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      background-color: #262626;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: scroll; } }
  .navigation__wrapper {
    max-width: 780px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }

@media only screen and (max-width: 950px) and (max-height: 800px) {
  .navigation__wrapper {
    padding: 150px 0 100px; } }

@media only screen and (max-width: 950px) and (max-height: 620px) {
  .navigation__wrapper {
    padding: 250px 0 100px; } }

@media only screen and (max-width: 950px) and (max-height: 520px) {
  .navigation__wrapper {
    padding: 300px 0 100px; } }

@media only screen and (max-width: 950px) and (max-height: 470px) {
  .navigation__wrapper {
    padding: 400px 0 100px; } }
    .navigation__wrapper ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center; }
      @media only screen and (max-width: 950px) {
        .navigation__wrapper ul {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          overflow: hidden; } }
      .navigation__wrapper ul li {
        position: relative; }
        .navigation__wrapper ul li:not(:last-child) {
          margin-right: 30px; }
          @media only screen and (max-width: 950px) {
            .navigation__wrapper ul li:not(:last-child) {
              margin-right: 0; } }
        .navigation__wrapper ul li:not(:first-child):before {
          content: url("../img/icons/dott.svg");
          position: absolute;
          top: 22px;
          left: -18px;
          width: 1px;
          height: 1px; }
          @media only screen and (max-width: 950px) {
            .navigation__wrapper ul li:not(:first-child):before {
              display: none; } }
        .navigation__wrapper ul li:hover ul.sub-menu {
          opacity: 1;
          visibility: visible;
          transform: translateY(0); }
        .navigation__wrapper ul li a {
          display: block;
          padding: 20px 0;
          font-family: "Montserrat", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 23px;
          color: #666464;
          transition: all .3s ease-in-out;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
          @media only screen and (max-width: 950px) {
            .navigation__wrapper ul li a {
              color: #ffffff;
              padding: 10px 0; } }
          .navigation__wrapper ul li a:hover {
            color: #ffffff;
            text-shadow: 0 0 0.75px #ffffff, 0 0 0.75px #ffffff; }
          .navigation__wrapper ul li a--services {
            position: relative;
            cursor: pointer; }
            @media only screen and (max-width: 950px) {
              .navigation__wrapper ul li a--services {
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0); } }
            .navigation__wrapper ul li a--services:hover .navigation__link--hovered {
              transform: translateY(0);
              opacity: 1;
              visibility: visible; }
        .navigation__wrapper ul li ul.sub-menu {
          padding: 7px 20px;
          background-color: #ffffff;
          box-shadow: 0px 0px 15px rgba(224, 44, 44, 0.7);
          border-radius: 0px 3px 3px 3px;
          position: absolute;
          top: 66px;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          white-space: nowrap;
          transform: translateY(-20px);
          opacity: 0;
          visibility: hidden;
          transition: all .3s ease-in-out;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
          @media only screen and (max-width: 950px) {
            .navigation__wrapper ul li ul.sub-menu {
              position: relative;
              opacity: 1;
              visibility: visible;
              top: 0;
              background-color: #262626;
              box-shadow: none;
              border-radius: none;
              padding: 5px 10px 0;
              transform: translateY(0); } }
          .navigation__wrapper ul li ul.sub-menu:before {
            content: '';
            clip-path: polygon(50% 0%, 0 70%, 100% 70%);
            position: absolute;
            width: 28px;
            height: 20px;
            top: -13px;
            left: 15px;
            background-color: #ffffff;
            box-shadow: 0px 0px 15px rgba(224, 44, 44, 0.7); }
            @media only screen and (max-width: 950px) {
              .navigation__wrapper ul li ul.sub-menu:before {
                display: none; } }
          .navigation__wrapper ul li ul.sub-menu li {
            margin: 0;
            padding: 0; }
            .navigation__wrapper ul li ul.sub-menu li:before {
              display: none; }
          .navigation__wrapper ul li ul.sub-menu li a {
            font-family: "Montserrat", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 1px;
            color: #666464;
            display: block;
            transition: all .3s ease-in-out; }
            @media only screen and (max-width: 950px) {
              .navigation__wrapper ul li ul.sub-menu li a {
                color: #ffffff;
                opacity: .7;
                line-height: 20px; } }
            .navigation__wrapper ul li ul.sub-menu li a:hover {
              color: #000000;
              text-shadow: 0 0 0.75px #000000, 0 0 0.75px #000000; }

.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 28px; }
  @media only screen and (max-width: 950px) {
    .breadcrumbs {
      display: none; } }
  .breadcrumbs__link, .breadcrumbs__current {
    font-family: "PT Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #ffffff;
    opacity: .3;
    transition: all .3s ease-in-out; }
    .breadcrumbs__link:hover, .breadcrumbs__current:hover {
      opacity: .7; }
  .breadcrumbs__current {
    opacity: .6; }
  .breadcrumbs__separator {
    margin: 0 10px;
    width: 12px;
    opacity: .3;
    position: relative;
    color: #ffffff;
    font-size: 10px; }

.hamburger {
  padding: 0;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  display: none; }
  @media only screen and (max-width: 950px) {
    .hamburger {
      display: inline-block;
      z-index: 9999999;
      position: relative; } }
  .hamburger.is-active .hamburger-inner {
    background-color: #fff; }
    .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
      background-color: #fff; }

.hamburger-box {
  width: 32px;
  height: 20px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
  width: 32px;
  height: 2px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    width: 32px;
    height: 2px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -9px; }
  .hamburger-inner::after {
    bottom: -9px; }

.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.header-template {
  background: linear-gradient(181.18deg, #262626 54.6%, rgba(38, 38, 38, 0) 99.24%);
  background-position: center;
  background-size: cover; }
  .header-template__inner-wrapper {
    padding: 20px 20px 110px;
    background-color: #262626;
    background-position: center;
    background-size: cover;
    min-height: 390px; }
    @media only screen and (max-width: 950px) {
      .header-template__inner-wrapper {
        padding: 120px 40px 100px;
        margin: 0 20px;
        min-height: auto; } }
    @media only screen and (max-width: 530px) {
      .header-template__inner-wrapper {
        padding: 100px 0 100px; } }
    .header-template__inner-wrapper-index {
      padding: 120px 20px 115px;
      background-image: url(../img/pages/main/bg.png); }
    .header-template__inner-wrapper-prices {
      background-image: url(../img/pages/prices/bg.png); }
    .header-template__inner-wrapper-gallery {
      background-image: url(../img/pages/gallery/bg.png); }
    .header-template__inner-wrapper-akpp-repair {
      background-image: url(../img/pages/akpp-repair/bg.png); }
    .header-template__inner-wrapper-variator {
      background-image: url(../img/pages/variator/bg.png); }
    .header-template__inner-wrapper-dsg {
      background-image: url(../img/pages/dsg/bg.png); }
    .header-template__inner-wrapper-rkpp {
      background-image: url(../img/pages/rkpp/bg.png); }
    .header-template__inner-wrapper-oil {
      background-image: url(../img/pages/oil/bg.png); }
    .header-template__inner-wrapper-hydroblock {
      background-image: url(../img/pages/hydroblock/bg.png); }
    .header-template__inner-wrapper-complex-diagnostics {
      background-image: url(../img/pages/complex-diagnostics/bg.png); }
    .header-template__inner-wrapper-torgue-converter {
      background-image: url(../img/pages/torgue-converter/bg.png); }
  .header-template__questions--wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: -60px; }
    @media only screen and (max-width: 1450px) {
      .header-template__questions--wrapper {
        margin-bottom: 0; } }
  .header-template__questions--question {
    width: 600px;
    margin-left: 50px;
    margin-right: -50px; }
    @media only screen and (max-width: 1450px) {
      .header-template__questions--question {
        width: 200px;
        margin: 0; } }
    @media only screen and (max-width: 600px) {
      .header-template__questions--question {
        display: none; } }
    .header-template__questions--question img {
      width: 100%; }
  .header-template__dark {
    background-color: #262626; }
  .header-template__list {
    display: flex;
    flex-direction: column;
    margin: 33px 0 71px; }
    .header-template__list--item {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .header-template__list--item:not(:last-child) {
        margin-bottom: 14px; }
    .header-template__list--img {
      margin-right: 19px; }
  .header-template__undertitle {
    margin: 10px 0 40px; }
    .header-template__undertitle--title {
      margin-top: 30px; }
    .header-template__undertitle--wrapper {
      max-width: 880px;
      margin: 30px auto 0; }
      @media only screen and (max-width: 480px) {
        .header-template__undertitle--wrapper {
          margin: 30px 10px 0; } }
      .header-template__undertitle--wrapper > *:not(:last-child) {
        margin-bottom: 30px; }
  .header-template__main-text {
    margin-bottom: 72px;
    max-width: 477px;
    line-height: 28px; }
  .header-template__adress {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 420px;
    margin: 20px auto 0; }
    .header-template__adress > *:not(:last-child) {
      margin-right: 70px; }
      @media only screen and (max-width: 500px) {
        .header-template__adress > *:not(:last-child) {
          margin-right: 30px; } }
    .header-template__adress--wrapper > *:not(:last-child) {
      margin-bottom: 30px; }
      @media only screen and (max-width: 500px) {
        .header-template__adress--wrapper > *:not(:last-child) {
          margin-bottom: 10px; } }
    @media only screen and (max-width: 500px) {
      .header-template__adress--wrapper-mobile {
        display: none; } }
    .header-template__adress-mobile {
      display: none; }
      @media only screen and (max-width: 500px) {
        .header-template__adress-mobile {
          display: block; } }
  .header-template__buttons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 100px; }
    @media only screen and (max-width: 950px) {
      .header-template__buttons-wrapper {
        margin-top: 30px; } }
    @media only screen and (max-width: 770px) {
      .header-template__buttons-wrapper {
        flex-direction: column;
        align-items: flex-start; }
        .header-template__buttons-wrapper > *:not(:last-child) {
          margin-bottom: 30px; } }
    @media only screen and (max-width: 530px) {
      .header-template__buttons-wrapper {
        margin-top: 60px;
        align-items: center; } }
  .header-template__title-rkpp {
    max-width: 620px; }

.button {
  padding: 16px 30px;
  background-color: #ED2D2D;
  border-radius: 3px;
  border: 1px solid #ED2D2D;
  display: inline-block;
  font-family: "Russo One", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: #ffffff;
  transition: all .3s ease-in-out;
  outline: none; }
  @media only screen and (max-width: 950px) {
    .button {
      font-size: 14px;
      padding: 15px 28px; } }
  .button:hover {
    box-shadow: 0px 0px 20px rgba(224, 44, 44, 0.7); }
  .button__transparent {
    background-color: transparent; }
  .button__header {
    margin-right: 30px; }
    @media only screen and (max-width: 770px) {
      .button__header {
        margin: 0; } }

.info-blocks__wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 50px -20px 0; }
  @media only screen and (max-width: 1000px) {
    .info-blocks__wrapper {
      justify-content: center; } }
  @media only screen and (max-width: 800px) {
    .info-blocks__wrapper {
      margin: 30px -20px 0; } }

@media only screen and (max-width: 1000px) {
  .info-blocks__title {
    text-align: center; } }

.info-blocks__block {
  width: 192px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 20px; }
  .info-blocks__block:not(:last-child) {
    margin-bottom: 50px; }
  .info-blocks__block--title {
    margin-bottom: 15px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #ED2D2D; }
  .info-blocks__block--img {
    height: 56px;
    margin-bottom: 6px; }
    .info-blocks__block--img img {
      height: 100%; }
  .info-blocks__block--text {
    line-height: 20px;
    font-size: 14px;
    width: 220px; }

.questions__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }
  @media only screen and (max-width: 800px) {
    .questions__wrapper {
      justify-content: center; } }

@media only screen and (max-width: 800px) {
  .questions__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; } }

.questions__text {
  max-width: 312px;
  margin: 15px 0 20px; }

.questions__phone {
  margin-bottom: 39px; }

.questions__img {
  max-width: 648px;
  margin-right: -100px; }
  @media only screen and (max-width: 1200px) {
    .questions__img {
      margin: 0; } }
  @media only screen and (max-width: 1000px) {
    .questions__img {
      max-width: 448px; } }
  @media only screen and (max-width: 800px) {
    .questions__img {
      display: none; } }
  .questions__img img {
    width: 100%; }

.question {
  color: #ED2D2D;
  font-size: 100px;
  font-family: "PT Sans", sans-serif; }

.checkpoints__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px; }
  @media only screen and (max-width: 650px) {
    .checkpoints__wrapper {
      flex-direction: column;
      margin-top: 20px; } }
  @media only screen and (max-width: 650px) {
    .checkpoints__wrapper--inner:not(:last-child) {
      margin-bottom: 20px; } }

.checkpoints__checkpoint {
  display: flex;
  flex-direction: row;
  align-items: center; }
  @media only screen and (max-width: 650px) {
    .checkpoints__checkpoint {
      text-align: left; } }
  .checkpoints__checkpoint:not(:last-child) {
    margin-bottom: 20px; }
  .checkpoints__checkpoint--img {
    margin-right: 23px; }

.banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }
  .banner__line {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: 1px;
    width: 100%;
    border-bottom: 1px dashed #ED2D2D;
    z-index: 0; }
  .banner__body {
    padding: 30px 94px;
    border: 1px dashed #ED2D2D;
    border-radius: 3px;
    width: fit-content;
    margin: 0 20px;
    background-color: #ffffff;
    z-index: 2;
    position: relative; }
    @media only screen and (max-width: 800px) {
      .banner__body {
        padding: 30px 35px; } }
    .banner__body-margin {
      margin-top: 25px; }
    .banner__body--text-center {
      text-align: center; }
    .banner__body--dark {
      background-color: #262626; }

.slider {
  padding: 70px 0 156px; }
  @media only screen and (max-width: 600px) {
    .slider {
      padding: 50px 0 150px; } }
  @media only screen and (max-width: 450px) {
    .slider {
      padding: 50px 0 100px; } }
  .slider__gallery {
    padding: 130px 0 0; }
    @media only screen and (max-width: 1010px) {
      .slider__gallery {
        padding: 50px 0 100px; } }
    @media only screen and (max-width: 700px) {
      .slider__gallery {
        padding: 50px 0 50px; } }
  .slider__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 50px auto 0;
    max-width: 780px; }
    @media only screen and (max-width: 650px) {
      .slider__wrapper {
        margin-top: 30px; } }
  .slider__slide {
    outline: none;
    display: block;
    overflow: hidden; }
    .slider__slide--img {
      max-width: 780px;
      outline: none; }
      .slider__slide--img img {
        width: 100%;
        height: auto; }
  .slider__iframe {
    outline: none;
    height: 439px !important;
    overflow: hidden !important; }
    @media only screen and (max-width: 650px) {
      .slider__iframe {
        height: 350px !important; } }
    @media only screen and (max-width: 550px) {
      .slider__iframe {
        height: 290px !important; } }
    @media only screen and (max-width: 450px) {
      .slider__iframe {
        height: 200px !important; } }
    .slider__iframe iframe, .slider__iframe object, .slider__iframe embed {
      display: block !important;
      width: 100% !important;
      height: 100% !important; }
  .slider__arrow {
    width: 71px;
    height: 71px;
    cursor: pointer;
    display: block;
    background: none;
    border: none;
    z-index: 99; }
    .slider__arrow img {
      width: 100%; }
    @media only screen and (max-width: 600px) {
      .slider__arrow {
        width: 51px;
        height: 51px; } }
    .slider__arrow--left {
      margin-left: -73px; }
      @media only screen and (max-width: 1010px) {
        .slider__arrow--left {
          position: absolute;
          top: 117%;
          left: 40%; } }
      @media only screen and (max-width: 600px) {
        .slider__arrow--left {
          top: 120%; } }
      @media only screen and (max-width: 400px) {
        .slider__arrow--left {
          top: 127%; } }
    .slider__arrow--right {
      margin-right: -73px; }
      @media only screen and (max-width: 1010px) {
        .slider__arrow--right {
          position: absolute;
          top: 117%;
          right: 40%; } }
      @media only screen and (max-width: 600px) {
        .slider__arrow--right {
          top: 120%; } }
      @media only screen and (max-width: 400px) {
        .slider__arrow--right {
          top: 127%; } }
    .slider__arrow img {
      width: 100%; }

.gallery__arrow {
  width: 71px;
  height: 71px;
  cursor: pointer;
  display: block;
  background: none;
  border: none;
  z-index: 99;
  outline: none; }
  .gallery__arrow img {
    width: 100%; }
  @media only screen and (max-width: 600px) {
    .gallery__arrow {
      width: 51px;
      height: 51px; } }
  .gallery__arrow--left {
    position: absolute;
    left: -5%;
    margin-left: -73px !important; }
    @media only screen and (max-width: 1010px) {
      .gallery__arrow--left {
        bottom: -25%;
        left: 40%; } }
    @media only screen and (max-width: 400px) {
      .gallery__arrow--left {
        bottom: -30%; } }
  .gallery__arrow--right {
    margin-right: -73px;
    position: absolute;
    right: -3%; }
    @media only screen and (max-width: 1010px) {
      .gallery__arrow--right {
        bottom: -25%;
        right: 40%; } }
    @media only screen and (max-width: 400px) {
      .gallery__arrow--right {
        bottom: -30%; } }

.slick-prev:before,
.slick-next:before {
  display: none; }

.after-head-banner {
  padding-bottom: 65px; }
  .after-head-banner__body {
    max-width: 625px;
    margin: -50px auto 0;
    padding: 50px 60px 57px;
    background-color: #F4EFEF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 3px; }
    @media only screen and (max-width: 950px) {
      .after-head-banner__body {
        padding: 20px 25px; } }
    .after-head-banner__body > *:not(:last-child) {
      margin-bottom: 30px; }

.checkers {
  padding: 70px 0; }
  .checkers__0 {
    padding: 0; }
  .checkers__title {
    margin-bottom: 30px; }
  .checkers__wrapper {
    margin-top: 38px; }
    .checkers__wrapper > *:not(:last-child) {
      margin-bottom: 40px; }
  .checkers__case {
    display: flex;
    flex-direction: row;
    align-items: center; }
    @media only screen and (max-width: 700px) {
      .checkers__case {
        flex-direction: column; } }
    .checkers__case--reverse {
      flex-direction: row-reverse; }
      @media only screen and (max-width: 700px) {
        .checkers__case--reverse {
          flex-direction: column; } }
    .checkers__case--img {
      width: 480px;
      border-radius: 3px;
      overflow: hidden; }
      @media only screen and (max-width: 700px) {
        .checkers__case--img {
          margin-bottom: 10px;
          width: 100%; } }
      .checkers__case--img img {
        width: 100%; }
    .checkers__case--info {
      max-width: 483px; }
      @media only screen and (max-width: 700px) {
        .checkers__case--info {
          max-width: 100%; } }
      .checkers__case--info-straight {
        padding-left: 20px; }
        @media only screen and (max-width: 700px) {
          .checkers__case--info-straight {
            padding: 0; } }
      .checkers__case--info-reverse {
        padding-right: 20px; }
        @media only screen and (max-width: 700px) {
          .checkers__case--info-reverse {
            padding: 0; } }
      .checkers__case--info-text {
        margin-top: 20px; }

.faq__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 64px; }
  .faq__header--img {
    width: 40px; }
    .faq__header--img img {
      width: 100%; }
  .faq__header--title {
    max-width: 780px;
    margin-left: 40px; }

.faq__wrapper {
  margin-top: 40px; }

.faq__question:not(:last-child) {
  margin-bottom: 40px; }

.faq__question--header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  border-top: 1px solid #ED2D2D;
  cursor: pointer; }
  .faq__question--header-imgwrapper {
    min-width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ED2D2D;
    border-radius: 0 3px 3px 3px;
    position: relative; }
  .faq__question--header-img {
    width: 38px; }
    .faq__question--header-img-rotate {
      position: absolute;
      top: 42%;
      left: 29%;
      transition: all .1s ease-in-out;
      transform: translate(-50%, -50%);
      transform: rotate(90deg); }
    .faq__question--header-img img {
      width: 100%; }
  .faq__question--header-header {
    padding-left: 20px;
    padding-right: 100px; }
    @media only screen and (max-width: 600px) {
      .faq__question--header-header {
        padding-right: 0; } }
    @media only screen and (max-width: 368px) {
      .faq__question--header-header {
        padding: 5px 0 0 10px; } }

.faq__question--body {
  margin-top: 40px; }
  .faq__question--body-wrapper {
    max-width: 930px;
    margin: 0 auto; }
  .faq__question--body-listwrapper {
    margin: 40px 0; }
  .faq__question--body-list {
    display: flex;
    flex-direction: row; }
    .faq__question--body-list:not(:last-child) {
      margin-bottom: 15px; }
    .faq__question--body-list-img {
      margin-right: 15px; }
  .faq__question--body-redbanner {
    background-color: #ED2D2D;
    border-radius: 3px;
    width: fit-content;
    margin: 0 auto 20px;
    padding: 17px 36px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff; }
    .faq__question--body-redbanner span {
      font-weight: bold; }

.it-block__wrapper {
  margin-top: 30px;
  display: flex;
  flex-direction: row; }
  @media only screen and (max-width: 850px) {
    .it-block__wrapper {
      flex-direction: column; } }
  .it-block__wrapper--reverse {
    flex-direction: row-reverse; }
    @media only screen and (max-width: 850px) {
      .it-block__wrapper--reverse {
        flex-direction: column; } }

.it-block__img {
  max-width: 378px;
  border-radius: 3px;
  overflow: hidden; }
  @media only screen and (max-width: 850px) {
    .it-block__img {
      margin-bottom: 20px; } }
  .it-block__img img {
    width: 100%;
    height: auto; }

.it-block__text {
  max-width: 647px; }
  .it-block__text--straight {
    margin-left: 20px; }
    @media only screen and (max-width: 850px) {
      .it-block__text--straight {
        margin: 0; } }
  .it-block__text--reverse {
    margin: 0 70px 0 0; }
  .it-block__text > *:not(:last-child) {
    margin-bottom: 30px; }

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all .3s ease-in-out; }
  .modal__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media only screen and (max-width: 950px) {
      .modal__wrapper {
        margin-top: 74px;
        overflow: scroll;
        height: auto; } }
  .modal__form {
    position: relative;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.8);
    width: fit-content;
    max-width: 500px;
    text-align: center; }
    @media only screen and (max-width: 590px) {
      .modal__form {
        padding: 15px 20px; } }
    @media only screen and (max-width: 590px) {
      .modal__form h1 {
        font-size: 14px;
        line-height: 20px; } }
    .modal__form--close {
      position: absolute;
      top: 4px;
      right: 4px;
      width: 15px;
      cursor: pointer; }
      .modal__form--close img {
        width: 100%; }
    .modal__form--field {
      margin: 20px 0;
      text-align-last: left;
      width: 100%; }
      @media only screen and (max-width: 590px) {
        .modal__form--field {
          margin: 10px 0; } }
      .modal__form--field input {
        border: none;
        outline: none;
        width: 100%;
        height: 50px;
        font-family: "Russo One", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        line-height: 19px;
        letter-spacing: 0.05em;
        color: #000000;
        transition: all .3s ease-in-out; }
        @media only screen and (max-width: 590px) {
          .modal__form--field input {
            height: 30px; } }
      .modal__form--field input[type=number]::-webkit-inner-spin-button,
      .modal__form--field input[type=number]::-webkit-outer-spin-button {
        appearance: none;
        margin: 0; }
      .modal__form--field span {
        font-family: "PT Sans", sans-serif;
        font-weight: 100;
        color: #ED2D2D; }

.modal-success {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all .3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center; }
  .modal-success__wrapper {
    background-color: rgba(255, 255, 255, 0.9);
    width: fit-content;
    max-width: 500px;
    text-align: center;
    padding: 20px; }

.footer {
  display: flex;
  flex-direction: row;
  max-height: 560px; }
  @media only screen and (max-width: 950px) {
    .footer {
      flex-direction: column; } }
  .footer__map {
    width: 100%;
    z-index: 1; }
    @media only screen and (max-width: 950px) {
      .footer__map {
        height: 400px; } }
    .footer__map iframe {
      border: 0;
      padding: 0;
      margin: 0;
      display: block; }
  .footer__info {
    padding: 72px 140px 50px 72px;
    width: 480px; }
    @media only screen and (max-width: 950px) {
      .footer__info {
        width: auto;
        padding: 50px 80px;
        display: flex;
        flex-direction: row;
        justify-content: space-between; } }
    @media only screen and (max-width: 700px) {
      .footer__info {
        padding: 40px 20px; } }
    @media only screen and (max-width: 560px) {
      .footer__info {
        flex-direction: column;
        padding: 40px 20px 0; } }
    .footer__info--wrapper-mobile {
      display: none; }
      @media only screen and (max-width: 950px) {
        .footer__info--wrapper-mobile {
          display: block; } }
  .footer__logo {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .footer__logo--text {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #ED2D2D;
      max-width: 104px;
      margin-left: 13px; }
  .footer__hours {
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin: 42px 0 28px; }
    .footer__hours span {
      font-weight: bold; }
  .footer__mail, .footer__adress, .footer__inst, .footer__rights {
    font-family: "PT Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: #B4B2B2;
    display: block;
    transition: all .3s ease-in-out; }
    .footer__mail:hover, .footer__adress:hover, .footer__inst:hover, .footer__rights:hover {
      color: #ffffff; }
  .footer__adress {
    margin: 38px 0 31px;
    max-width: 278px; }
    .footer__adress span {
      font-weight: bold; }
  .footer__inst {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 37px; }
    @media only screen and (max-width: 950px) {
      .footer__inst {
        margin-top: 11px; } }
    .footer__inst--img {
      margin-left: 13px; }
  .footer__rights {
    color: #ffffff;
    font-size: 14px;
    line-height: 15px;
    max-width: 290px; }

@media only screen and (max-width: 950px) {
  .fmdn {
    display: none; } }

.info__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }
  @media only screen and (max-width: 860px) {
    .info__wrapper {
      flex-direction: column;
      align-items: flex-start; } }
  .info__wrapper--block-title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 100px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #ED2D2D; }
    @media only screen and (max-width: 860px) {
      .info__wrapper--block-title {
        font-size: 80px; } }
    .info__wrapper--block-title span {
      font-size: 50px;
      margin-left: 30px;
      margin-top: 40px; }
      @media only screen and (max-width: 860px) {
        .info__wrapper--block-title span {
          font-size: 30px; } }

.info__text {
  max-width: 522px;
  margin-top: 20px; }
  @media only screen and (max-width: 860px) {
    .info__text {
      margin-bottom: 30px; } }

.brands__wrapper {
  margin: 33px -20px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }
  @media only screen and (max-width: 1100px) {
    .brands__wrapper {
      margin: 33px 0 0; } }

.brands__brand {
  background: #ffffff;
  border: 1px solid #F3F1F1;
  box-sizing: border-box;
  border-radius: 3px;
  width: 122px !important;
  height: 128px !important;
  margin: 0 20px 35px;
  padding: 14px 9px 40px;
  position: relative;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  outline: none;
  transition: all .3s ease-in-out; }
  @media only screen and (max-width: 1100px) {
    .brands__brand {
      margin: 7px 15px 20px 8px; } }
  .brands__brand:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); }
    @media only screen and (max-width: 1100px) {
      .brands__brand:hover {
        box-shadow: none; } }
  .brands__brand--text {
    position: absolute;
    bottom: 12px; }
  .brands__brand--img img {
    width: 100%; }
  .brands__brand--acura {
    width: 69px; }
  .brands__brand--audi {
    width: 106px; }
  .brands__brand--bmw {
    width: 67px; }
  .brands__brand--chevrolet {
    width: 103px; }
  .brands__brand--citroen {
    width: 95px; }
  .brands__brand--cadillac {
    width: 61px; }
  .brands__brand--daewoo {
    width: 64px; }
  .brands__brand--fiat {
    width: 71px; }
  .brands__brand--jeep {
    width: 102px; }
  .brands__brand--ford {
    width: 103px; }
  .brands__brand--infiniti {
    width: 106px; }
  .brands__brand--kia {
    width: 103px; }
  .brands__brand--dodge {
    width: 64px; }
  .brands__brand--hyundai {
    width: 102px; }
  .brands__brand--land-rover {
    width: 94px; }
  .brands__brand--honda {
    width: 101px; }
  .brands__brand--mitsubishi {
    width: 60px; }
  .brands__brand--mazda {
    width: 104px; }
  .brands__brand--mb {
    width: 68px; }
  .brands__brand--porche {
    width: 105px; }
  .brands__brand--opel {
    width: 86px; }
  .brands__brand--isuzu {
    width: 102px; }
  .brands__brand--renault {
    width: 58px; }
  .brands__brand--lexus {
    width: 101px; }
  .brands__brand--nissan {
    width: 79px; }
  .brands__brand--sy {
    width: 102px; }
  .brands__brand--saab {
    width: 67px; }
  .brands__brand--suzuki {
    width: 104px; }
  .brands__brand--subaru {
    width: 105px; }
  .brands__brand--peugueot {
    width: 97px; }
  .brands__brand--wolkswagen {
    width: 64px; }
  .brands__brand--skoda {
    width: 57px; }
  .brands__brand--toyota {
    width: 81px; }
  .brands__brand--jaguar {
    width: 102px; }
  .brands__brand--ar {
    width: 66px; }
  .brands__brand--hummer {
    width: 104px; }
  .brands__brand--volvo {
    width: 67px; }

.slick-slide {
  outline: none; }

.services {
  padding: 80px 20px 60px; }
  @media only screen and (max-width: 900px) {
    .services {
      padding: 50px 0; } }
  .services__wrapper {
    margin: 50px -20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media only screen and (max-width: 1192px) {
      .services__wrapper {
        justify-content: center;
        margin: 30px -20px 0; } }
  .services__case {
    display: block;
    width: 246px;
    height: 331px;
    margin: 0 20px;
    border-radius: 3px;
    border: 1px solid #ED2D2D;
    position: relative;
    transition: all .3s ease-in-out; }
    .services__case:not(:last-child) {
      margin-bottom: 30px; }
    .services__case:hover {
      box-shadow: 0px 0px 20px rgba(224, 44, 44, 0.7); }
    .services__case--img {
      width: 100%;
      height: 173px; }
      .services__case--img img {
        width: 100%;
        height: 100%; }
    .services__case--wrapper {
      padding: 23px 8px;
      text-align: center; }
    .services__case--title {
      line-height: 1.2; }
    .services__case--info {
      font-weight: 300;
      margin-top: 10px;
      line-height: 1.2; }
    .services__case--text {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      bottom: 20px; }

.how-do-we-work {
  padding: 70px 0 20px; }
  @media only screen and (max-width: 1200px) {
    .how-do-we-work {
      padding: 70px 0 10px; } }
  .how-do-we-work__wrapper {
    margin-top: 66px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media only screen and (max-width: 1200px) {
      .how-do-we-work__wrapper {
        justify-content: center;
        margin: 66px -20px 0; } }
    @media only screen and (max-width: 800px) {
      .how-do-we-work__wrapper {
        margin-top: 40px; } }
  .how-do-we-work__case {
    margin-bottom: 50px; }
    @media only screen and (max-width: 1200px) {
      .how-do-we-work__case {
        margin: 0 20px 50px; } }
    .how-do-we-work__case--text {
      height: 44px;
      margin-bottom: 20px;
      max-width: 187px;
      line-height: 22px; }
    .how-do-we-work__case--img {
      max-width: 278px;
      max-height: 185px;
      border-radius: 3px;
      overflow: hidden; }
      .how-do-we-work__case--img img {
        width: 100%;
        height: 100%; }

.equipment {
  padding: 80px 0 50px; }
  @media only screen and (max-width: 900px) {
    .equipment {
      padding: 60px 0 30px; } }
  .equipment__wrapper {
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 48px -10px 0; }
    @media only screen and (max-width: 1282px) {
      .equipment__wrapper {
        margin: 48px 0 0; } }
    @media only screen and (max-width: 900px) {
      .equipment__wrapper {
        margin-top: 40px; } }
  .equipment__case {
    width: 280px;
    height: 670px;
    margin: 0 10px 30px;
    border: 1px solid #ED2D2D;
    box-sizing: border-box;
    border-radius: 3px;
    overflow: hidden;
    transition: all .3s ease-in-out; }
    .equipment__case:hover {
      box-shadow: 0px 0px 20px rgba(224, 44, 44, 0.7); }
    .equipment__case--img {
      width: 100%;
      height: 321px; }
      .equipment__case--img img {
        width: 100%;
        height: 100%; }
    .equipment__case--wrapper {
      padding: 20px 20px; }
    .equipment__case--info {
      opacity: .5;
      margin-top: 20px; }

.masters {
  text-align: center; }
  .masters__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1140px;
    margin: 60px auto 0; }
    @media only screen and (max-width: 1180px) {
      .masters__wrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .masters__wrapper > *:not(:last-child) {
          margin-bottom: 40px; } }
    @media only screen and (max-width: 1180px) and (max-width: 590px) {
      .masters__wrapper > *:not(:last-child) {
        margin-bottom: 30px; } }
  .masters__master {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(224, 44, 44, 0.25);
    border-radius: 0px 3px 3px 0px;
    text-align: left;
    width: 543px;
    height: 340px;
    display: flex;
    flex-direction: row;
    transition: all .3s ease-in-out; }
    @media only screen and (max-width: 590px) {
      .masters__master {
        flex-direction: column;
        width: auto;
        height: auto; } }
    .masters__master:hover {
      box-shadow: 0px 0px 25px rgba(224, 44, 44, 0.25); }
    .masters__master--img {
      width: 275px;
      height: 100%; }
      .masters__master--img img {
        width: 100%;
        height: 100%; }
    .masters__master--info {
      padding: 75px 31px; }
      @media only screen and (max-width: 590px) {
        .masters__master--info {
          padding: 50px 30px; } }
    .masters__master--title {
      font-weight: bold; }
    .masters__master--text {
      margin: 30px 0 60px; }
    .masters__master--muted {
      opacity: .4;
      max-width: 214px; }

.variator-dignities__blocks {
  margin: 60px auto 40px;
  max-width: 940px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media only screen and (max-width: 900px) {
    .variator-dignities__blocks {
      justify-content: center; }
      .variator-dignities__blocks > *:not(:last-child) {
        margin: 0 30px 30px; } }

.variator-dignities__block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  max-width: 140px; }
  .variator-dignities__block--img {
    max-height: 55px; }
    .variator-dignities__block--img img {
      height: 100%; }
  .variator-dignities__block--title {
    margin-top: 15px; }

.variator-dignities__text-wrapper {
  max-width: 1050px; }
  .variator-dignities__text-wrapper > *:not(:last-child) {
    margin-bottom: 30px; }

.prices__wrapper {
  display: flex;
  flex-direction: row;
  margin-left: -20px;
  margin-top: 30px; }
  @media only screen and (max-width: 1050px) {
    .prices__wrapper {
      margin-left: 0; } }
  @media only screen and (max-width: 900px) {
    .prices__wrapper {
      overflow: auto;
      white-space: nowrap; } }
  .prices__wrapper > *:not(:last-child) {
    margin-right: 30px; }
  .prices__wrapper--column {
    display: flex;
    flex-direction: column; }
    .prices__wrapper--column > * {
      padding: 10px 20px; }
    .prices__wrapper--column-red-item {
      background-color: #ED2D2D;
      color: #ffffff;
      min-height: 23px;
      border-radius: 3px; }
    .prices__wrapper--column-item {
      background-color: #ED2D2D;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      border-radius: 3px;
      padding-left: 20px; }
    .prices__wrapper--column-align-end {
      align-items: flex-end; }
    .prices__wrapper--column-red {
      background-color: #ED2D2D;
      border-radius: 3px; }
      .prices__wrapper--column-red > * {
        color: #ffffff; }

.why-us__wrapper {
  margin-top: 30px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }
  @media only screen and (max-width: 1208px) {
    .why-us__wrapper {
      flex-direction: column;
      align-items: center; }
      .why-us__wrapper > *:not(:last-child) {
        margin-bottom: 20px; } }

.why-us__case {
  max-width: 378px;
  border-radius: 3px;
  overflow: hidden; }
  .why-us__case--img {
    max-width: 380px; }
    .why-us__case--img img {
      width: 100%; }
  .why-us__case--title {
    margin-top: 15px; }

.garanty__wrapper {
  margin: 40px 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap; }
  .garanty__wrapper-text {
    max-width: 1000px;
    margin: 0 auto; }
    .garanty__wrapper-text > *:not(:last-child) {
      margin-bottom: 30px; }

.garanty__case {
  margin: 0 70px 60px;
  width: 244px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center; }
  .garanty__case--img {
    height: 70px; }
    .garanty__case--img img {
      height: 100%; }
  .garanty__case--text {
    margin-top: 10px; }

.advantages__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 880px;
  margin: 0 auto;
  margin-top: 53px; }
  .advantages__wrapper > *:not(:last-child) {
    margin-bottom: 90px; }
  @media only screen and (max-width: 900px) {
    .advantages__wrapper {
      justify-content: center; } }
  @media only screen and (max-width: 590px) {
    .advantages__wrapper {
      flex-direction: column;
      align-items: center; } }

.advantages__case {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  max-width: 231px;
  margin: 0 30px; }
  @media only screen and (max-width: 900px) {
    .advantages__case {
      margin: 0 20px; } }
  .advantages__case--img {
    height: 56px; }
    .advantages__case--img img {
      height: 100%; }
  .advantages__case--text {
    margin-top: 10px; }

.repair__items-wrapper {
  margin: 60px 0 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap; }
  @media only screen and (max-width: 1020px) {
    .repair__items-wrapper {
      margin: 30px 0; } }

.repair__item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  width: 286px;
  margin: 0 20px 30px; }
  .repair__item--img {
    height: 50px; }
    .repair__item--img img {
      height: 100%; }
  .repair__item--title {
    margin-top: 10px; }

.how-to-repair-akpp--text {
  max-width: 1020px;
  margin-top: 30px; }

.how-to-repair-akpp__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 32px; }
  @media only screen and (max-width: 1200px) {
    .how-to-repair-akpp__wrapper {
      justify-content: center; } }

.how-to-repair-akpp__case {
  max-width: 341px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px 50px; }
  @media only screen and (max-width: 600px) {
    .how-to-repair-akpp__case {
      margin: 0 0 20px; } }
  .how-to-repair-akpp__case--img {
    max-width: 341px; }
    .how-to-repair-akpp__case--img img {
      width: 100%; }
  .how-to-repair-akpp__case--text {
    max-width: 220px;
    line-height: 20px;
    margin-top: 14px; }

body {
  font-family: "Russo One", sans-serif;
  font-weight: normal;
  color: #000000;
  position: relative; }

.container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px; }
  .container__middle {
    max-width: 980px; }
  .container__thin {
    max-width: 860px; }
  .container__text-center {
    text-align: center; }
    @media only screen and (max-width: 800px) {
      .container__text-center {
        text-align: left; } }
  .container__mobile-header {
    padding: 0; }
