.faq {
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 64px;
    &--img {
      width: 40px;
      img {
        width: 100%; } }
    &--title {
      max-width: 780px;
      margin-left: 40px; } }
  &__wrapper {
    margin-top: 40px; }
  &__question {
    &:not(:last-child) {
      margin-bottom: 40px; }
    &--header {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 80px;
      border-top: 1px solid $main__red;
      cursor: pointer;
      &-imgwrapper {
        min-width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: $main__red;
        border-radius: 0 3px 3px 3px;

        position: relative; }
      &-img {
        width: 38px;
        &-rotate {
          position: absolute;
          top: 42%;
          left: 29%;
          transition: all .1s ease-in-out;
          transform: translate(-50%, -50%);
          transform: rotate(90deg); }
        img {
          width: 100%; } }
      &-header {
        padding-left: 20px;
        padding-right: 100px;
        @media only screen and (max-width: 600px) {
          padding-right: 0; }
        @media only screen and (max-width: 368px) {
          padding: 5px 0 0 10px; } } }
    &--body {
      margin-top: 40px;
      // transition: all .1s ease-in-out
      &-wrapper {
        max-width: 930px;
        margin: 0 auto; }
      &-listwrapper {
        margin: 40px 0; }
      &-list {
        display: flex;
        flex-direction: row;
        // align-items: center
        &:not(:last-child) {
          margin-bottom: 15px; }
        &-img {
          margin-right: 15px; } }
      &-redbanner {
        background-color: $main__red;
        border-radius: 3px;
        width: fit-content;
        margin: 0 auto 20px;
        padding: 17px 36px;

        font-family: $montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        color: $main__white;
        span {
          font-weight: bold; } } } } }



