.why-us {
  &__wrapper {
    margin-top: 30px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @media only screen and (max-width: 1208px) {
      flex-direction: column;
      align-items: center;
      & > * {
       &:not(:last-child) {
        margin-bottom: 20px; } } } }
  &__case {
    max-width: 378px;
    border-radius: 3px;
    overflow: hidden;
    &--img {
      max-width: 380px;
      img {
        width: 100%; } }
    &--title {
      margin-top: 15px; } } }
